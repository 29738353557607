.frame {
    min-width: 860px;
}

.iconbutton__button--medium {
    width: 50px;
    height: 50px;
}

.div--gauge {
    display: flex;
    width: 220px;
    height: 30px;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    align-content: center;
}

.dialog__header--warnning {
    text-shadow: '1px 1px 2px #000';
    font-weight: 'bolder';
    font-size: 180;
    padding-bottom: 32;
}