/* .slick-prev,
.slick-next
{
    font-size: 20px;
} */

.slick-prev:before,
.slick-next:before
{
    color: #1a3a69;
    font-size: 23px;
}

/* .nextArrow {
    top: 0;
    right: -45px;
}
  
.prevArrow {
    top: 0;
    left: -45px;
} */

.label__box {
    position: absolute;
    margin: 0px;
    padding: 0px;
    width: 4.7em;
    height: 2.4em;
    font-size: 10px;
    font-weight: bolder;
    text-align: center;
    overflow: hidden;
    z-index: auto;
}
.label__box--font {
    margin: 0px;
    padding: 0px;
    padding-top: 0.1em;
    width: 100%;
    line-height: 1.1em;
}
.label__box:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 4.7em;
    height: 0.7em;
    background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.5) 50%);
}
.label__box:hover {
    overflow: visible;
    opacity: 0.90;
    background-color: white;
    height: 5.1em;
    z-index: 100;
}