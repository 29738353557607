/* Margin */
.mt-0 {
    margin-top: 0px;
}
.mt-1 {
    margin-top: 8px;
}
.mt-2 {
    margin-top: 16px;
}
.mt-3 {
    margin-top: 24px;
}
.mt-4 {
    margin-top: 32px;
}
.mt-5 {
    margin-top: 40px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-1 {
    margin-bottom: 8px;
}
.mb-2 {
    margin-bottom: 16px;
}
.mb-3 {
    margin-bottom: 24px;
}
.mb-4 {
    margin-bottom: 32px;
}
.mb-5 {
    margin-bottom: 40px;
}
.ml-0 {
    margin-left: 0px;
}
.ml-1 {
    margin-left: 8px;
}
.ml-2 {
    margin-left: 16px;
}
.ml-3 {
    margin-left: 24px;
}
.ml-4 {
    margin-left: 32px;
}
.ml-5 {
    margin-left: 40px;
}
.mr-0 {
    margin-right: 0px;
}
.mr-1 {
    margin-right: 8px;
}
.mr-2 {
    margin-right: 16px;
}
.mr-3 {
    margin-right: 24px;
}
.mr-4 {
    margin-right: 32px;
}
.mr-5 {
    margin-right: 40px;
}

/* Padding*/
.p-0 {
    padding: 0px;
}
.p-1 {
    padding: 8px;
}
.p-2 {
    padding: 16px;
}
.p-3 {
    padding: 24px;
}
.p-4 {
    padding: 32px;
}
.p-5 {
    padding: 40px;
}
.pt-0 {
    padding-top: 0px;
}
.pt-1 {
    padding-top: 8px;
}
.pt-2 {
    padding-top: 16px;
}
.pt-3 {
    padding-top: 24px;
}
.pt-4 {
    padding-top: 32px;
}
.pt-5 {
    padding-top: 40px;
}
.pr-0 {
    padding-right: 0px;
}
.pr-1 {
    padding-right: 8px;
}
.pr-2 {
    padding-right: 16px;
}
.pr-3 {
    padding-right: 24px;
}
.pr-4 {
    padding-right: 32px;
}
.pr-5 {
    padding-right: 40px;
}
.pb-0 {
    padding-bottom: 0px;
}
.pb-1 {
    padding-bottom: 8px;
}
.pb-2 {
    padding-bottom: 16px;
}
.pb-3 {
    padding-bottom: 24px;
}
.pb-4 {
    padding-bottom: 32px;
}
.pb-5 {
    padding-bottom: 40px;
}
.pl-0 {
    padding-left: 0px;
}
.pl-1 {
    padding-left: 8px;
}
.pl-2 {
    padding-left: 16px;
}
.pl-3 {
    padding-left: 24px;
}
.pl-4 {
    padding-left: 32px;
}
.pl-5 {
    padding-left: 40px;
}