body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Margin */
.mt-0 {
    margin-top: 0px;
}
.mt-1 {
    margin-top: 8px;
}
.mt-2 {
    margin-top: 16px;
}
.mt-3 {
    margin-top: 24px;
}
.mt-4 {
    margin-top: 32px;
}
.mt-5 {
    margin-top: 40px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-1 {
    margin-bottom: 8px;
}
.mb-2 {
    margin-bottom: 16px;
}
.mb-3 {
    margin-bottom: 24px;
}
.mb-4 {
    margin-bottom: 32px;
}
.mb-5 {
    margin-bottom: 40px;
}
.ml-0 {
    margin-left: 0px;
}
.ml-1 {
    margin-left: 8px;
}
.ml-2 {
    margin-left: 16px;
}
.ml-3 {
    margin-left: 24px;
}
.ml-4 {
    margin-left: 32px;
}
.ml-5 {
    margin-left: 40px;
}
.mr-0 {
    margin-right: 0px;
}
.mr-1 {
    margin-right: 8px;
}
.mr-2 {
    margin-right: 16px;
}
.mr-3 {
    margin-right: 24px;
}
.mr-4 {
    margin-right: 32px;
}
.mr-5 {
    margin-right: 40px;
}

/* Padding*/
.p-0 {
    padding: 0px;
}
.p-1 {
    padding: 8px;
}
.p-2 {
    padding: 16px;
}
.p-3 {
    padding: 24px;
}
.p-4 {
    padding: 32px;
}
.p-5 {
    padding: 40px;
}
.pt-0 {
    padding-top: 0px;
}
.pt-1 {
    padding-top: 8px;
}
.pt-2 {
    padding-top: 16px;
}
.pt-3 {
    padding-top: 24px;
}
.pt-4 {
    padding-top: 32px;
}
.pt-5 {
    padding-top: 40px;
}
.pr-0 {
    padding-right: 0px;
}
.pr-1 {
    padding-right: 8px;
}
.pr-2 {
    padding-right: 16px;
}
.pr-3 {
    padding-right: 24px;
}
.pr-4 {
    padding-right: 32px;
}
.pr-5 {
    padding-right: 40px;
}
.pb-0 {
    padding-bottom: 0px;
}
.pb-1 {
    padding-bottom: 8px;
}
.pb-2 {
    padding-bottom: 16px;
}
.pb-3 {
    padding-bottom: 24px;
}
.pb-4 {
    padding-bottom: 32px;
}
.pb-5 {
    padding-bottom: 40px;
}
.pl-0 {
    padding-left: 0px;
}
.pl-1 {
    padding-left: 8px;
}
.pl-2 {
    padding-left: 16px;
}
.pl-3 {
    padding-left: 24px;
}
.pl-4 {
    padding-left: 32px;
}
.pl-5 {
    padding-left: 40px;
}
.frame {
    min-width: 860px;
}

.iconbutton__button--medium {
    width: 50px;
    height: 50px;
}

.div--gauge {
    display: flex;
    width: 220px;
    height: 30px;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    align-content: center;
}

.dialog__header--warnning {
    text-shadow: '1px 1px 2px #000';
    font-weight: 'bolder';
    font-size: 180;
    padding-bottom: 32;
}
/* .slick-prev,
.slick-next
{
    font-size: 20px;
} */

.slick-prev:before,
.slick-next:before
{
    color: #1a3a69;
    font-size: 23px;
}

/* .nextArrow {
    top: 0;
    right: -45px;
}
  
.prevArrow {
    top: 0;
    left: -45px;
} */

.label__box {
    position: absolute;
    margin: 0px;
    padding: 0px;
    width: 4.7em;
    height: 2.4em;
    font-size: 10px;
    font-weight: bolder;
    text-align: center;
    overflow: hidden;
    z-index: auto;
}
.label__box--font {
    margin: 0px;
    padding: 0px;
    padding-top: 0.1em;
    width: 100%;
    line-height: 1.1em;
}
.label__box:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 4.7em;
    height: 0.7em;
    background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.5) 50%);
}
.label__box:hover {
    overflow: visible;
    opacity: 0.90;
    background-color: white;
    height: 5.1em;
    z-index: 100;
}
.table_cell__box--prj {
    margin: 0px;
    padding: 0px;
    max-height: 180px;
    overflow: hidden;
    width: 100%;
    margin-left: 6px;
}
/* 
.table_cell__box--folder_name {
    margin-left: -16px;
}

.table_cell__box--file_name {
    margin-left: -8px;
} */

.table_cell--prj_overflow {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.2rem;
    overflow-wrap: break-word;
}

.select--large {
    width: 490px;
}

.iconbutton__button--medium {
    width: 50px;
    height: 50px;
}

.input__msg--error {
    /* margin-Left: 32px; */
    color: #FF0000;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.whiteIcon {
    fill: 'white'
}
.table__header {
    font-size: 1rem;
    font-weight: bolder;
    color: #000000;
}

.table__column--small {
    width: 40px;
}

.table__column--medium {
    width: 110px;
}

.table__column--large {
    width: 330px;
}

.table__column--storage-class {
    width: 70px;
}

/* .table__row {
    height: 72px;
} */

.table__cell {
    /* margin-left: 3px; */
    /* padding-left: 3px; */
    min-width: 100%;
}

.table_cell__box {
    margin: 0px;
    padding: 0px;
    max-height: 40px;
    overflow: hidden;
    width: '100%';
}

.table_cell__box--folder_name {
    margin-left: -16px;
}

.table_cell__box--file_name {
    margin-left: -8px;
}

.table_cell--overflow {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 2rem;
    overflow-wrap: break-word;
}

.table__cell--small-font {
    font-size: 0.9rem;
}

.table__cell--grey {
    color: #808080;
}

.table__cell--bold {
    font-weight: bold;
}

.breadcrumb__font {
    font-size: 1rem;
    font-weight: bold;
    color: #808080;
}

.breadcrumb__font--link {
    font-size: 1rem;
    font-weight: bold;
    color: #3366CC;
}

.table__cell--link {
    /* font-size: 1rem; */
    font-weight: bold;
    color: #3366CC;
}
/* .slick-prev,
.slick-next
{
    font-size: 20px;
} */

.slick-prev:before,
.slick-next:before
{
    color: #1a3a69;
    font-size: 23px;
}

/* .nextArrow {
    top: 0;
    right: -45px;
}
  
.prevArrow {
    top: 0;
    left: -45px;
} */

.label2__box {
    position: static;
    margin: 0px;
    padding: 0px;
    width: 4.7em;
    height: 2.0em;
    text-align: center;
    overflow: hidden;
    z-index: auto;
}
.label2__box--font {
    margin: 0px;
    padding: 0px;
    padding-top: 0.1em;
    width: 50px;
    font-size: 10px;
    font-weight: bolder;
    text-align: center;
    line-height: 1.1em;
}
/* .label2__box:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 4.7em;
    height: 0.7em;
    background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.5) 50%);
}
.label2__box:hover {
    overflow: visible;
    opacity: 0.90;
    background-color: white;
    height: 5.1em;
    z-index: 100;
} */

.slick-slider .slick-list {
    display: flex !important;
  }
  
.slick-slider .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: flex-start !important;
  }

.my-custom-slider .slick-list {
display: flex !important;
}

.my-custom-slider .slick-track {
display: flex !important;
justify-content: flex-start !important;
position: absolute
}
