/* .slick-prev,
.slick-next
{
    font-size: 20px;
} */

.slick-prev:before,
.slick-next:before
{
    color: #1a3a69;
    font-size: 23px;
}

/* .nextArrow {
    top: 0;
    right: -45px;
}
  
.prevArrow {
    top: 0;
    left: -45px;
} */

.label2__box {
    position: static;
    margin: 0px;
    padding: 0px;
    width: 4.7em;
    height: 2.0em;
    text-align: center;
    overflow: hidden;
    z-index: auto;
}
.label2__box--font {
    margin: 0px;
    padding: 0px;
    padding-top: 0.1em;
    width: 50px;
    font-size: 10px;
    font-weight: bolder;
    text-align: center;
    line-height: 1.1em;
}
/* .label2__box:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 4.7em;
    height: 0.7em;
    background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.5) 50%);
}
.label2__box:hover {
    overflow: visible;
    opacity: 0.90;
    background-color: white;
    height: 5.1em;
    z-index: 100;
} */

.slick-slider .slick-list {
    display: flex !important;
  }
  
.slick-slider .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: flex-start !important;
  }

.my-custom-slider .slick-list {
display: flex !important;
}

.my-custom-slider .slick-track {
display: flex !important;
justify-content: flex-start !important;
position: absolute
}