.table__header {
    font-size: 1rem;
    font-weight: bolder;
    color: #000000;
}

.table__column--small {
    width: 40px;
}

.table__column--medium {
    width: 110px;
}

.table__column--large {
    width: 330px;
}

.table__column--storage-class {
    width: 70px;
}

/* .table__row {
    height: 72px;
} */

.table__cell {
    /* margin-left: 3px; */
    /* padding-left: 3px; */
    min-width: 100%;
}

.table_cell__box {
    margin: 0px;
    padding: 0px;
    max-height: 40px;
    overflow: hidden;
    width: '100%';
}

.table_cell__box--folder_name {
    margin-left: -16px;
}

.table_cell__box--file_name {
    margin-left: -8px;
}

.table_cell--overflow {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 2rem;
    overflow-wrap: break-word;
}

.table__cell--small-font {
    font-size: 0.9rem;
}

.table__cell--grey {
    color: #808080;
}

.table__cell--bold {
    font-weight: bold;
}

.breadcrumb__font {
    font-size: 1rem;
    font-weight: bold;
    color: #808080;
}

.breadcrumb__font--link {
    font-size: 1rem;
    font-weight: bold;
    color: #3366CC;
}

.table__cell--link {
    /* font-size: 1rem; */
    font-weight: bold;
    color: #3366CC;
}