.table_cell__box--prj {
    margin: 0px;
    padding: 0px;
    max-height: 180px;
    overflow: hidden;
    width: 100%;
    margin-left: 6px;
}
/* 
.table_cell__box--folder_name {
    margin-left: -16px;
}

.table_cell__box--file_name {
    margin-left: -8px;
} */

.table_cell--prj_overflow {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.2rem;
    overflow-wrap: break-word;
}

.select--large {
    width: 490px;
}

.iconbutton__button--medium {
    width: 50px;
    height: 50px;
}

.input__msg--error {
    /* margin-Left: 32px; */
    color: #FF0000;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.whiteIcon {
    fill: 'white'
}